<template>
	<div
		class="wrapper"
		:class="{
			['background-' + pageColor]: !isScrolled && status,
			'background-aili-white': isScrolled && status,
			'pointer-events-none': !status
		}"
		v-click-outside="closeBar"
	>
		<v-text-field
			ref="global-search-text"
			dense
			autofocus
			:dark="!isScrolled"
			class="search"
			:color="isScrolled ? pageColor : '#fff'"
			:class="{
				closed: !status,
				['color-' + pageColor]: isScrolled
			}"
			:value="searchText"
			@change="onChangeSearch"
		>
			<template v-slot:append-outer>
				<div>
					<v-icon
						class="magnify"
						@click="onGlobalSearchClick"
						:color="
							$vuetify.breakpoint.width >= 1024 && isScrolled
								? pageColor
								: 'white'
						"
					>
						mdi-magnify
					</v-icon>
				</div>
			</template>
		</v-text-field>
	</div>
</template>
<script>
export default {
	props: ["isScrolled"],
	data() {
		return {
			is_writing: false,
			searchText: "",
			status: false
		};
	},
	watch: {
		searchText(newValue) {
			this.updateProperty({
				property: "globalSearchTerm",
				value: newValue
			});
			this.goTo("Search", {
				term: this.searchText
			});
		}
	},
	methods: {
		closeBar() {
			this.status = false;
			this.searchText = "";
		},
		onChangeSearch(value) {
			if (value.length > 0) {
				this.searchText = value;
				this.$emit("onGlobalSearch");
			}
		},
		onGlobalSearchClick() {
			if (this.status) {
				if (this.searchText.length > 0) {
					this.goTo("Search", {
						term: this.searchText
					});
				}
				this.$emit("onGlobalSearch");
			} else {
				this.status = true;
				setTimeout(() => {
					this.$refs["global-search-text"].focus();
				}, 100);
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.closed {
	max-width: 30px;
}
.magnify {
	pointer-events: all;
}

.wrapper {
	border-radius: 50px;
	display: inline-flex;
	flex-direction: row-reverse;
	position: absolute;
	right: 0;
	transition: 0.1s all;
	width: calc(100% - 160px);
}
.pointer-events-none {
	pointer-events: none;
}
</style>
