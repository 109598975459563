<template>
	<div class="loginButton" @click="onLoginClick">
		<slot></slot>
	</div>
</template>

<script>
export default {
	mounted() {
		let loginData = this.loginData;
		loginData.dialog = false;
		this.updateProperty({
			property: 'loginData',
			value: loginData,
		});
	},
	methods: {
		onLoginClick() {
			let loginData = this.loginData;
			loginData.dialog = !loginData.dialog;
			this.updateProperty({
				property: 'loginData',
				value: loginData,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.loginButton {
	cursor: pointer;
}
</style>
