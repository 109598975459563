<template>
	<div class="main-column">
		<slot />
	</div>
</template>

<script>
export default {
	name: 'MainColumn',
};
</script>

<style lang="scss" scoped>
.main-column {
	clear: both;
	position: relative;
	margin: 0 auto;
	height: auto;
	max-width: 1200px;
	width: 95%;
}
</style>
