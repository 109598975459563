import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				'aili-blue': '#00aeef',
				'aili-blue-light': '#bfedff',
				'aili-yellow': '#ff8500',
				'aili-yellow-light': '#ffe7cc',
				'aili-pink': '#f72585',
				'aili-pink-light': '#fed3e7',
				'aili-purple': '#7209b7',
				'aili-purple-light': '#e3cdf1',
				'aili-green': '#00cca0',
				'aili-green-light': '#ccf8ee',
				'aili-white': '#fff',
				'aili-black': '#000000',
			},
		},
	},
});
