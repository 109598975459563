<template>
	<div>
		<v-dialog v-model="toggleModal" max-width="740px">
			<v-fade-transition mode="out-in">
				<div
					v-if="loginData.status && loginData.user.data"
					class="success-login"
					:class="`background-${pageColor} color-aili-white`"
				>
					<SuccessCheckbox />
					Benvenuto {{ loginData.user.data.first_name[0] }}
				</div>
				<div
					v-if="forgotPasswordSent"
					class="success-login"
					:class="`background-${pageColor} color-aili-white`"
				>
					<SuccessCheckbox />
					Ti abbiamo inviato una email per il recupero password.
				</div>
			</v-fade-transition>
			<div
				v-if="
					(!loginData.status || !loginData.user.data) &&
						!forgotPasswordSent
				"
				class="form-content"
			>
				<div class="login-form">
					<LoginForm
						v-if="!loginData.status"
						@onLoginSuccess="onLoginSuccess"
						@onLoginError="onLoginError"
						@onForgotPasswordSend="onForgotPasswordSend"
					/>
				</div>
				<div
					class="login-render-blocks"
					:class="{
						[`background-${pageColor}`]: true
					}"
				>
					<div>
						<h3>
							Associati e ottieni immediatamente tantissimi vantaggi!
						</h3>
						<br class="clear" />
						<Button
							:value="register.post_title"
							@click="onSubscribeButtonClick"
							:color="pageColor"
							:inverted="true"
						/>
					</div>
				</div>
				<br class="clear" />
			</div>
		</v-dialog>
	</div>
</template>

<script>
import LoginForm from "@/components/ui/LoginForm.vue";
import SuccessCheckbox from "@/components/ui/SuccessCheckbox.vue";

export default {
	name: "LoginDialog",
	components: {
		LoginForm,
		SuccessCheckbox
	},
	data() {
		return {
			forgotPasswordSent: false,
			password: "",
			register: [],
			showSubscribeForm: false,
			username: ""
		};
	},
	computed: {
		toggleModal: {
			get() {
				return this.loginData.dialog;
			},
			set(newValue) {
				let loginData = this.loginData;
				loginData.dialog = newValue;

				this.updateProperty({
					property: "loginData",
					value: loginData
				});
			}
		}
	},
	async mounted() {
		let response_register = await this.$api.get(
			"/qzr-data/qzr_fp_pages/qzr_um_registration_page/"
		);
		this.register = response_register.data[0];
	},
	methods: {
		onLoginSuccess() {
			setTimeout(() => (this.loginData.dialog = false), 2000);
		},
		onLoginError() {},
		onNewUserClick() {
			this.showSubscribeForm = true;
		},
		onForgotPasswordSend() {
			this.forgotPasswordSent = true;
			setTimeout(() => {
				this.loginData.dialog = false;
			}, 5000);
			setTimeout(() => {
				this.forgotPasswordSent = false;
			}, 6000);
		},
		onSubscribeButtonClick() {
			this.loginData.dialog = false;
			this.goTo("Page", { slug: this.register.post_name });
		}
	}
};
</script>

<style lang="scss" scoped>
.login {
	background: #fff;
	padding: $spacing-1;
	border-radius: 0;
	@media (max-width: $mobile-m){
		padding: $spacing-0;
	}
}
.success-login {
	align-items: center;
	display: flex;
	justify-content: center;
	height: 400px;
	transition: all 0.5s;
	width: 100%;
	overflow-y: hidden;
}
.forgot-password {
	display: inline-block;
	width: 100%;
	margin: $spacing-0 0;
}
.login-form {
	display: inline-block;
	height: 400px;
	text-align: center;
	transition: all 0.5s;
	width: 50%;
	@media (max-width: $tablet-m) {
		width: 100%;
		height: auto;
	}
}
.subscribeButton {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	border-radius: 90px;
	border: 2px solid #fff;
	padding: 6px 15px;
}
.form-content {
	display: inline-flex;
	transition: all 0.5s;
	@media (max-width: $tablet-m) {
		width: 100%;
		display: inline-block;
	}
}
.login-render-blocks {
	display: inline-block;
	color: #fff;
	height: 400px;
	padding: $spacing-1;
	transition: all 0.5s;
	width: 50%;
	h2 {
		@media (max-width: $tablet-m) {
			display: none;
		}
	}
	@media (max-width: $tablet-m) {
		padding: $spacing-0;
		width: 100%;
		height: auto;
	}
}
</style>
