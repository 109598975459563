import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		municipalities: null,
		states: null,
		pageTitles: null,
		intersectingPanel: '',
		pageColor: 'aili-blue',
		searchTerm: null,
		globalSearchTerm: null,
		recipeCategories: [],
		articleCategories: [],
		selectedPanel: null,
		seo: false,
		loadingData: true,
		loginData: {
			dialog: false,
			status: false,
			error: false,
			nonce: false,
			user: false,
		},
	},
	mutations: {
		updateProperty(state, { property, value }) {
			state[property] = value;
		},
	},
	actions: {
		updateProperty(context, { property, value }) {
			context.commit('updateProperty', { property, value });
		},
	},
	modules: {},
});
