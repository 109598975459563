<template>
	<div class="mobile-menu" @touchmove="onTouchMove">
		<div class="dropped active" :class="{ openMenu: openMenu }" />
		<MainColumn>
			<Logo class="main-logo" size="100" :color="openMenu ? '#fff' : pageColor" />

			<div class="profile-icon">
				<div v-if="!loginData.user">
					<LoginDialogButton>
						<Button :color="openMenu ? '#fff' : pageColor" icon="mdi-account" />
					</LoginDialogButton>
				</div>
				<div v-else @click="goTo('Page', { slug: 'area-riservata' })">
					<v-icon :color="openMenu ? '#fff' : pageColor">
						mdi-account
					</v-icon>
				</div>
			</div>
			<MenuIcon @onChangeStatus="onChangeStatus" :updateOpenMenu="openMenu" />
		</MainColumn>
		<div class="menu-wrap" :class="{ active: openMenu }">
			<MainColumn>
				<div v-for="page in menus" class="button" :key="page.ID">
					<a
						:class="{
							active: currentSlug == page.slug,
						}"
						:href="getUrl(page.relative_url)"
						@click="(e) => goToPage(e, page.slug)"
						>{{ page.title }}</a
					>
					<div class="submenu" :class="{ active: openSubmenu == page.ID }">
						<div :key="subpage.ID" v-for="subpage in submenu['id_' + page.ID]">
							<div
								class="button"
								:class="{
									active: $route.params.subpage == subpage.slug,
								}"
								:href="getUrl(subpage.relative_url)"
								@click="(e) => goToPage(e, page.slug, subpage.slug)"
								v-html="subpage.title"
							/>
						</div>
					</div>
					<div
						v-if="submenu['id_' + page.ID]"
						class="open-submenu-icon"
						:class="{ active: openSubmenu == page.ID }"
						@click="(e) => toggleSubmenu(page.ID)"
					>
						<div class="bar-toggle"></div>
						<div class="bar-toggle"></div>
					</div>
				</div>
				<!-- <LoginDialogButton v-if="!loginData.user"
					><span class="login button">Accedi</span>
				</LoginDialogButton> -->
				<GlobalSearchMobile @onGlobalSearch="openMenu = false" />
			</MainColumn>
		</div>
	</div>
</template>

<script>
import MenuIcon from '@/components/ui/MenuIcon.vue';
import GlobalSearchMobile from '@/components/ui/GlobalSearchMobile.vue';
import Logo from '@/components/ui/Logo.vue';
import LoginDialogButton from '@/components/ui/authentication/LoginDialogButton.vue';
export default {
	name: 'Header',
	props: ['data', 'isScrolled', 'currentSlug'],
	components: { MenuIcon, Logo, LoginDialogButton, GlobalSearchMobile },
	data() {
		return {
			menus: [],
			submenu: [],
			openMenu: false,
			openSubmenu: false,
		};
	},
	mounted() {
		this.data.map((singleMenu) => {
			this.addToSubmenuIfChild(singleMenu);
		});

		if (this.loginData.user) {
			this.menus.push({
				title: 'Area riservata',
				relative_url: '/area-riservata/',
				slug: 'area-riservata',
				ID: 'area-riservata',
			});
			this.submenu['id_area-riservata'] = [];
			this.submenu['id_area-riservata'].push({
				ID: 'id_profilo',
				title: 'Profilo',
				relative_url: '/area-riservata/profilo/',
				slug: 'profilo',
				post_parent: 'area-riservata',
			});
			this.submenu['id_area-riservata'].push({
				ID: 'id_tessera',
				title: 'Tessera',
				relative_url: '/area-riservata/tessera/',
				slug: 'tessera',
				post_parent: 'area-riservata',
			});
		}
	},
	methods: {
		addToSubmenuIfChild(singleMenu) {
			if (singleMenu.menu_item_parent && singleMenu.menu_item_parent > 0) {
				let indexParent = parseInt(singleMenu.menu_item_parent);
				if (!this.submenu['id_' + indexParent]) {
					this.submenu['id_' + indexParent] = [];
				}

				this.submenu['id_' + indexParent].push(singleMenu);
			} else {
				singleMenu.statusMenu = false;
				this.menus.push(singleMenu);
			}
		},
		goToPage(e, slug, subpage) {
			this.openMenu = false;
			e.preventDefault();
			switch (slug) {
				case 'articoli':
					this.goTo('Articles');
					break;
				case 'ricette':
					this.goTo('Recipes');
					break;

				default:
					if (!subpage) {
						this.goTo('Page', { slug: slug });
					} else {
						this.goTo('Page', { slug: slug, subpage: subpage });
					}
			}
		},
		onChangeStatus(openMenu) {
			this.openMenu = openMenu;
		},
		toggleSubmenu(pageId) {
			if (this.openSubmenu != pageId) {
				this.openSubmenu = pageId;
			} else {
				this.openSubmenu = false;
			}
		},
		onTouchMove(e) {
			e.preventDefault();
		},
	},
};
</script>

<style lang="scss" scoped>
#app {
  .profile-icon {
    position: absolute;
    top: 18px;
    right: 40px;
    z-index: 101;
    display: inline-block;
    width: 54px;
    cursor: pointer;
  }
  .menu-wrap {
    position: fixed;
    top: -100vh;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: scroll;
    width: 100%;
    height: 100vh;
    padding-top: 100px;
    padding-bottom: 250px;
    background: black;
    transition: top .3s;
    &::-webkit-scrollbar {
      display: none;
    }
    &.active {
      top: 0;
    }

    a,
    .button,
    .account,
    .login {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 32px;
      padding: 0;
      margin: 2px 0;
      color: #fff !important;
      font-size: 20px;
      line-height: 1;
      text-decoration: none;
      border: 0;
      &:hover {
        background: transparent;
      }
      &.active {
        text-decoration: underline;

        text-underline-offset: 30%;
      }
      .submenu {
        overflow: hidden;
        max-height: 0;
        padding-left: $spacing-0;
        transition: all .3s;
        &.active {
          max-height: 100vh;
          padding: $spacing-0;
          padding-top: 0;
          margin: 15px 0;
        }
      }

      @media (max-width: $tablet-l) {
        text-transform: none;
      }

      .open-submenu-icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        margin: 0;
        .bar-toggle {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 18px;
          height: 2px;
          margin-top: 0;
          background: #fff;
          transform: translate(-50%, -50%);
          transition: all .15s linear;
          opacity: 1;
        }
        .bar-toggle:nth-child(2) {
          transform: rotate(90deg) translate(0, 8px);
          transform-origin: 50% 0;
        }
        @media (max-width: $tablet-l) {
          display: block;
        }
        &.active .bar-toggle:nth-child(2) {
          transform: rotate(0) translate(-50%, -50%);
        }
      }
    }
  }

  .dropped {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: #fff;
    box-shadow: 10px -20px 25px 4px #000;
    transition: .3s all;
    &.openMenu {
      z-index: 101;
      background: #000;
    }
  }
  .main-logo {
    position: fixed;
    z-index: 101;
    margin: 15px 0;
  }
}
</style>
