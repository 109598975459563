<template>
	<div
		id="menu-btn"
		@click="toggleMenu"
		class="bar-icon-wrapper medium-screen right"
		:class="{ active: openMenu && updateOpenMenu }"
	>
		<div :class="'bar-icon background-' + pageColor"></div>
		<div :class="'bar-icon background-' + pageColor"></div>
		<div :class="'bar-icon background-' + pageColor"></div>
	</div>
</template>
<script>
export default {
	props: ["updateOpenMenu"],
	data() {
		return {
			openMenu: false
		};
	},
	methods: {
		toggleMenu() {
			this.openMenu = !this.openMenu;
			this.$emit("onChangeStatus", this.openMenu);
		}
	}
};
</script>
<style scoped lang="scss">
#menu-btn {
	z-index: 999;
	cursor: pointer;
	display: inline-block;
	border-radius: 100%;
	height: 32px;
	width: 24px;
	right: 0;
	margin: 15px 0;
	padding-top: 8px;
	position: absolute;

	.bar-icon-wrapper {
		padding: 8px;
		padding-top: 7px;
	}

	&.active .bar-icon {
		background: #fff !important;
	}
}

.bar-icon {
	height: 2px;
	width: 23px;
	display: block;
	opacity: 1;
	margin-top: 5px;
	transition: all 0.15s linear;
	transform-origin: 50% 50%;
}

.bar-toggle-wrapper {
	height: 30px;
	width: 30px;
	margin-right: 4px;
	position: relative;
	float: right;

	&.active {
		.bar-toggle:nth-child(2) {
			transform: rotate(0) translate(-50%, -50%);
		}
	}

	@media (max-width: $tablet-l) {
		display: block;
	}

	.bar-toggle {
		height: 2px;
		width: 23px;
		background: #fff;
		display: block;
		opacity: 1;
		margin-top: 0;
		transition: all 0.15s linear;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.bar-toggle:nth-child(2) {
		transform: rotate(90deg) translate(0, 10px);
		transform-origin: 50% 0;
	}
}

.bar-toggle-wrapper .active .bar-icon {
	width: 25px;
}
.active .bar-icon:nth-child(1) {
	transform: rotate(45deg) translate3d(5px, 5px, 0);
}

.active .bar-icon:nth-child(2) {
	opacity: 0;
}

.active .bar-icon:nth-child(3) {
	transform: rotate(-45deg) translate3d(5px, -5px, 0);
}
</style>
