<template>
	<svg
		@click="goTo('Home')"
		class="logo"
		xmlns="http://www.w3.org/2000/svg"
		:width="size ? size : '150'"
		height="40.958"
		viewBox="0 0 150 40.958"
	>
		<g id="Raggruppa_48" data-name="Raggruppa 48" transform="translate(-6077.1 -47.164)">
			<g id="Raggruppa_46" data-name="Raggruppa 46" transform="translate(6077.1 47.164)">
				<path
					id="Tracciato_4"
					data-name="Tracciato 4"
					d="M6.667,0-18.616,40.958h12.97l5.335-8.437h26.75l5.131,8.437H44.54L19.325,0Zm6.406,10.86,8.744,14.059H4.278Z"
					transform="translate(18.616)"
					:fill="fillColor"
				/>
			</g>
			<path
				id="Tracciato_60"
				data-name="Tracciato 60"
				d="M87.049-20.206h12.39V-61.164H87.049Z"
				transform="translate(6061.962 108.328)"
				:fill="fillColor"
			/>
			<g id="Raggruppa_47" data-name="Raggruppa 47" transform="translate(6175.445 47.164)">
				<path
					id="Tracciato_6"
					data-name="Tracciato 6"
					d="M0,0V40.958H30.661V33.524H12.478V0Z"
					:fill="fillColor"
				/>
			</g>
			<path
				id="Tracciato_61"
				data-name="Tracciato 61"
				d="M135.42-20.206h12.394V-61.164H135.42Z"
				transform="translate(6079.286 108.328)"
				:fill="fillColor"
			/>
		</g>
	</svg>
</template>
<script>
export default {
	props: ['color', 'size'],

	computed: {
		fillColor() {
			switch (this.color) {
				case 'aili-green':
					return '#00cca0';

				case 'aili-pink':
					return '#f72585';

				case 'aili-blue':
					return '#00abea';

				case 'aili-purple':
					return '#7209b7';
				case 'aili-yellow':
					return '#ff8500';

				default:
					return this.color;
			}
		},
	},
};
</script>
<style lang="scss" scoped>
svg {
	margin-top: -4px;
}
.logo,
.logo path,
.logo g {
	cursor: pointer;
	transition: 0.4s all;
}
</style>
