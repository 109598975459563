export const login = {
	methods: {

		async validateLogin() {
			try {
				await this.$api.get('/qzr-data/validate/');
				return true;
			} catch {
				this.invalidLogin('Invalid nonce');
				return false;
			}
		},
		invalidLogin(error) {
			let loginData = this.loginData;

			loginData.error = error;
			loginData.status = false;
			loginData.user = false;

			delete this.$api.defaults.headers.common['X-WP-Nonce'];

			this.updateProperty({
				property: 'loginData',
				value: loginData
			});

			this.$cookies.remove('nonce');
			/*let includes = ['nonce','wordpress_logged_in'];
			let cookies = document.cookie.split(";");
			for (let i = 0, j = cookies.length; i < j; i++){
				let cookieName = cookies[i].split("=")[0].trim();
				for (let k = 0, l = includes.length; k < l; k++){
					if(cookieName.indexOf(includes[k])>-1) {
						this.setCookie(cookieName,"",-1);
					}
				}
			}*/
		},
		/*
		setCookie(name, value, expirydays) {
			var d = new Date();
			d.setTime(d.getTime() + (expirydays*24*60*60*1000));
			var expires = "expires="+ d.toUTCString();
			document.cookie = name + "=" + value + "; " + expires;
		},*/

		async setNonceCookieAndUserData(nonce) {
			this.$api.defaults.headers.common['X-WP-Nonce'] = nonce;

			let loginData = this.loginData;

			loginData.status = true;
			loginData.error = false;
			loginData.nonce = nonce;
			loginData.user = await this.getUserData();

			this.updateProperty({
				property: 'loginData',
				value: loginData,
			});

			let today = new Date();
			let expire = new Date();
			expire.setTime(today.getTime() + 3600000 * 24 * 14);
			this.$cookies.set('nonce', nonce, expire.toGMTString());
		},

		async tryLoginCookies(){
			try {
				let nonce = this.$cookies.get('nonce') ? this.$cookies.get('nonce') : false;
				
				if(nonce){
					this.$api.defaults.headers.common['X-WP-Nonce'] = nonce;
					let isValid = await this.validateLogin();
					if(isValid){
						await this.setNonceCookieAndUserData(nonce);
					}
				}
			} catch (error) {
				//this.invalidLogin(error);
			}
		},
		
		async getUserData() {
			this.$api.defaults.withCredentials = true;

			try {
				return await this.$api.get('/qzr-data/user/');
			} catch {
				return false;
			}
		},

		async getNonce(user_id) {
			try {
				this.$api.defaults.withCredentials = true;
				let response = await this.$api.post('qzr-data/token', {
					user_id: user_id,
				});
				let nonce = response.data;

				await this.setNonceCookieAndUserData(nonce);
			} catch {
				// Invalid nonce
			}
		},

		async loginRecover() {
			let queryString = window.location.search;
			let urlParams = new URLSearchParams(queryString);
			let key = urlParams.get('k')
			let user_login = urlParams.get('ul')
			if (key && user_login) {
				let login_data = {
					key: key,
					user_login: user_login,
				};
				try {
					let response = await this.$api.post('qzr-data/recover', login_data);
					await this.getNonce(response.data);
					//location.reload()
					return true;
				} catch {
					return false;
					// Invalid user or password
				}
			} else {
				return false;
			}
		},

		async login(user = '', pass = '') {
			let login_data = {
				user: user,
				pass: pass,
			};
			try {
				let response = await this.$api.post('qzr-data/login', login_data);
				await this.getNonce(response.data);
			} catch {
				// Invalid user or password
				await this.invalidLogin('Credenziali non valide');
			}
		},

		logout() {
			this.invalidLogin(false);
		},
	},
};
