<template>
	<a
		@click="(e) => $emit('click', e)"
		@mouseover="hover = true"
		@mouseout="hover = false"
		class="button"
		:href="href"
		:target="target ? target : '_self'"
		:style="
			inverted
				? {
						['text-align']: left ? 'left' : 'center',
						['border-color']: hover ? '#fff' : noBorder ? 'transparent' : '#fff',
						['background-color']: active || (!noBorder && hover) ? '#fff' : colorHex,
						color: active || (!noBorder && hover) ? colorHex : '#fff',
				  }
				: {
						['text-align']: left ? 'left' : 'center',
						['border-color']: hover ? colorHex : noBorder ? 'transparent' : colorHex,
						['background-color']: active || (!noBorder && hover) ? colorHex : 'transparent',
						color: active || (!noBorder && hover) ? '#fff' : colorHex,
				  }
		"
		:class="{
			['button-' + color]: true,
			noBorder: noBorder,
			icon: icon,
			hover: hover,
			active: active,
			disabled: disabled,
			loading: loading,
		}"
	>
		<v-progress-circular indeterminate class="loading-icon" :color="colorHex" v-if="loading"></v-progress-circular>

		<span v-if="value && !loading" v-html="value"></span>
		<v-icon v-if="icon && !loading" :color="color">{{ icon }}</v-icon>
	</a>
</template>
<script>
export default {
	name: 'Button',
	props: [
		'color',
		'noBorder',
		'value',
		'href',
		'icon',
		'active',
		'inverted',
		'disabled',
		'target',
		'left',
		'loading',
	],
	data() {
		return {
			hover: false,
		};
	},
	computed: {
		colorHex() {
			let color;
			switch (this.color) {
				case 'aili-green':
					color = '#00cca0';
					break;
				case 'aili-yellow':
					color = '#ff8500';
					break;
				case 'aili-purple':
					color = '#7209b7';
					break;
				case 'aili-pink':
					color = '#f72585';
					break;
				case 'aili-blue':
					color = '#00aeef';
					break;
				default:
					color = this.color;
			}
			return color;
		},
	},
};
</script>
<style lang="scss" scoped>
#app {
	.loading-icon {
		height: 18px !important;
		width: 18px !important;
		pointer-events: none;
	}

	.button {
		text-decoration: none;
		margin-right: 10px;
		display: inline-block;
		color: #fff;
		text-transform: uppercase;
		border-radius: 90px;
		border: 2px solid #fff;
		padding: 6px 15px;
		transition: all 0.3s;
		font-size: $font-size-14;
		box-sizing: border-box;
		min-width: 37px;
		min-height: 37px;

		@media (max-width: $tablet-m) {
			width: 100%;
		}

		&.center {
			text-align: center;
		}

		&.noBorder {
			border: 2px solid transparent;
		}

		&.hover {
			border: 2px solid #fff;
		}

		&.icon {
			margin: 0 8px;
			width: 37px;
			height: 37px;
			padding: 3px 4px;
			display: inline-block;
		}
		&.active {
			pointer-events: none;
		}
		&.loading {
			opacity: 0.5;
			pointer-events: none;
		}
		&.disabled {
			border-color: #999 !important;
			color: #999 !important;
			pointer-events: none;
		}
	}
}
</style>
