import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import VueGtag from 'vue-gtag';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/404',
		name: '404',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/ricerca/:term?',
		name: 'Search',
		component: () => import('../views/Search.vue'),
	},
	{
		path: '/ricette/chef/:slug',
		name: 'Chef',
		component: () => import('../views/SingleChef.vue'),
	},
	{
		path: '/articoli/esperto/:slug',
		name: 'Expert',
		component: () => import('../views/SingleExpert.vue'),
	},
	{
		path: '/ricette/:category?',
		name: 'Recipes',
		component: () => import('../views/ArchiveRecipes.vue'),
	},

	{
		path: '/ricetta/:slug',
		name: 'Recipe',
		component: () => import('../views/Recipe.vue'),
		alias: '/recipe/:slug',
	},
	{
		path: '/articoli/:category?',
		name: 'Articles',
		component: () => import('../views/ArchiveArticles.vue'),
	},
	{
		path: '/articolo/:slug?',
		name: 'Article',
		component: () => import('../views/Article.vue'),
		alias: '/post/:slug',
	},
	{
		path: '/ricette/tag/:tag',
		name: 'Tag',
		component: () => import('../views/Tag.vue'),
	},
	{
		//We cannot use alias in the next router beacuse it will match the path with parameters slug and subpages
		//We could specify path: '/page/:slug?' and alias '/:slug/:subpage?'
		path: '/page/:slug?',
		name: 'PreviewPage',
		component: () => import('../views/Page.vue'),
	},
	{
		path: '/:slug/:subpage?',
		name: 'Page',
		component: () => import('../views/Page.vue'),
	},
	{
		path: '*',
		name: '404',
		component: () => import('../views/404.vue'),
	},
];

const router = new VueRouter({
	routes,
	mode: 'history',
	base: process.env.VUE_APP_BASE_ROUTER,
});

Vue.use(
	VueGtag,
	{
		// config: { id: 'UA-71632772-1' },
		config: { id: 'G-D097KFW5P8' },
	},
	router,
);

router.beforeEach(async (to, from, next) => {
	window.scrollTo(0, 0);
	next();
});

export default router;
