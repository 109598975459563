import { mapState, mapActions } from 'vuex';
import MainColumn from '@/components/ui/MainColumn';
import Button from '@/components/ui/Button';

export const utilities = {
	components: {
		MainColumn,
		Button,
	},
	computed: {
		...mapState([
			'articleCategories',
			'getRestUrl',
			'globalSearchTerm',
			'intersectingPanel',
			'loginData',
			'loadingData',
			'municipalities',
			'states',
			'pageColor',
			'pageTitles',
			'recipeCategories',
			'selectedPanel',
			'seo',
			'searchTerm',
		]),
		user_status() {
			if (!this.loginData.user) return '';

			if (this.loginData.user.data.qzr_status && this.loginData.user.data.qzr_status.length > 0) {
				return this.loginData.user.data.qzr_status[0];
			} else {
				return '';
			}
		},
	},
	methods: {
		...mapActions(['updateProperty']),
		parseAttribute(query) {
			if (!query) {
				return '';
			}
			return JSON.parse(decodeURIComponent(query));
		},
		setSchemaJson(jsonString) {
			let jsonSchema = document.createElement('script');
			jsonSchema.setAttribute('type', 'application/ld+json');

			jsonSchema.textContent = jsonString.replaceAll('"{', '{').replaceAll('}"', '}');
			document.head.appendChild(jsonSchema);
		},
		preventDefault(e) {
			e.preventDefault();
		},
		getUrl(relative) {
			if (!relative) {
				return;
			}
			if (relative.charAt(0) == '/') {
				relative = relative.substring(1);
			}
			return window.location.origin + '/' + relative;
		},
		async getPreview(url) {
			let config = {
				headers: {
					'X-WP-Nonce': this.$route.query._wpnonce,
				},
				params: {
					status: this.$route.query.status,
					'include[]': this.$route.params.slug,
				},
			};
			let response = await this.$api.get(url, config);
			return response;
		},
		htmlDecode(input) {
			let doc = new DOMParser().parseFromString(input, 'text/html');
			return doc.documentElement.textContent;
		},
		goTo(view, paramsValues = {}, e = null) {
			if (e) {
				e.preventDefault();
			}

			this.$router
				.push({
					params: paramsValues,
					name: view,
				})
				.catch((e) => {
					console.log(e);
					console.log('404');
				});
		},
		formatYoastMeta(post) {
			let yoast_meta = post['yoast_meta'];
			if (yoast_meta && yoast_meta.length > 0) {
				let meta = [];
				let title;

				//Handle all cases for description meta
				let description_index = yoast_meta.findIndex((single_meta) => single_meta['name'] === 'description');

				if (description_index === -1) {
					if (post.excerpt && post.excerpt.rendered) {
						meta.push({
							name: 'description',
							content: this.htmlDecode(post.excerpt.rendered).substring(0, 165) + '...',
						});
						meta.push({
							name: 'og:description',
							content: this.htmlDecode(post.excerpt.rendered).substring(0, 165) + '...',
						});
					} else if (post.post_excerpt) {
						meta.push({
							name: 'description',
							content: this.htmlDecode(post.post_excerpt).substring(0, 165) + '...',
						});
						meta.push({
							name: 'og:description',
							content: this.htmlDecode(post.post_excerpt).substring(0, 165) + '...',
						});
					}
				} else {
					meta.push({
						name: 'description',
						content: yoast_meta[description_index]['content'].substring(0, 165) + '...',
					});
					meta.push({
						name: 'og:description',
						content: yoast_meta[description_index]['content'].substring(0, 165) + '...',
					});
				}

				//All other metas
				for (let index in yoast_meta) {
					let singleMeta = yoast_meta[index];

					if (singleMeta['property'] == 'og:title' || singleMeta['property'] == 'title')
						title = singleMeta['content'];

					if (
						!singleMeta['name'] ||
						(singleMeta['name'] !== 'description' && singleMeta['name'] !== 'og:description')
					) {
						meta.push({
							name: singleMeta['property'] ? singleMeta['property'] : singleMeta['name'],
							content: singleMeta['content'],
						});
					}
				}

				return {
					title: title,
					meta: meta,
				};
			} else {
				return {
					title: 'Associazione AILI',
					description: 'Associazione AILI',
				};
			}
		},
		async getRecipeCategories() {
			if (this.recipeCategories.length === 0) {
				let recipeCategories = await this.$api.get('/wp/v2/recipe-category', {
					params: {
						hide_empty: true,
					},
				});
				recipeCategories = recipeCategories.data;
				recipeCategories.unshift({
					name: 'Tutte',
					slug: '',
				});
				recipeCategories.push({
					name: 'Playlist',
					slug: 'tags',
				});
				recipeCategories.push({
					name: 'Chef',
					slug: 'chef',
				});
				this.updateProperty({
					property: 'recipeCategories',
					value: recipeCategories,
				});
			}
		},
		async getArticleCategories() {
			if (this.articleCategories.length === 0) {
				let articleCategories = await this.$api.get('/wp/v2/categories', {
					params: {
						hide_empty: true,
					},
				});
				articleCategories = articleCategories.data;
				articleCategories.unshift({
					name: 'Tutti',
					slug: '',
				});

				articleCategories.push({
					name: 'Esperti',
					slug: 'esperti',
				});
				this.updateProperty({
					property: 'articleCategories',
					value: articleCategories,
				});
			}
		},
		titleToSlug(name) {
			if (name) {
				name = name
					.split(' ')
					.join('-')
					.toLowerCase();
				return name;
			}
		},
		sanitizeTitle(name) {
			if (name) {
				name = name.split(' ').join('_');
				return name;
			}
		},
		convertSlug(name) {
			if (name) {
				name = name.split('-').join(' ');
				name = name.split('_').join(' ');
				return name;
			}
		},

		$ready(fn) {
			if (process.env.NODE_ENV === 'production') {
				return this.$nextTick(fn);
			}

			setTimeout(() => {
				this.$nextTick(fn);
			});
		},
		get404() {
			this.goTo('404');
		},
		async getMunicipalities() {
			if (!this.municipalities) {
				let municipalities = await this.$localApi.get(process.env.VUE_APP_PUBLIC_PATH + 'comuni.json');
				municipalities = municipalities.data.map((municipality) => ({
					name: municipality.nome,
					coordinate: municipality.coordinate,
					cap: municipality.cap,
				}));

				this.updateProperty({
					property: 'municipalities',
					value: municipalities,
				});
			}
		},
		filterMunicipalitiesByName(item, queryText) {
			const textOne = item.name.toLowerCase();
			const cap = item.cap;
			const searchText = queryText.toLowerCase();
			return textOne.indexOf(searchText) > -1 || cap.indexOf(searchText) > -1;
		},
		async getStates() {
			if (!this.states) {
				let states = await this.$localApi.get(process.env.VUE_APP_PUBLIC_PATH + 'province_id.json');
				states = states.data.map((state) => ({
					name: state.name,
					iso: state.iso,
					id: state.id,
				}));
				states.sort(function(a, b) {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				});
				this.updateProperty({
					property: 'states',
					value: states,
				});
			}
		},
		filterStatesByName(item, queryText) {
			const textOne = item.name.toLowerCase();
			const iso = item.iso.toLowerCase();
			const searchText = queryText.toLowerCase();
			return textOne.indexOf(searchText) > -1 || iso.indexOf(searchText) > -1;
		},
		changeUrlWithoutNavigation(params) {
			let currentRoute = this.$route.path;
			let splittedCurrentRoute = currentRoute.split('/');
			if (splittedCurrentRoute.length === 3) {
				let currentTarget = splittedCurrentRoute[2];
				currentRoute = currentRoute.replace(currentTarget, encodeURIComponent(params));
			} else {
				currentRoute = currentRoute + '/' + encodeURIComponent(params);
			}
			history.pushState({}, null, currentRoute);
		},
		getObjectIfExist(obj, key) {
			return key.split('.').reduce(function(o, x) {
				return typeof o == 'undefined' || o === null ? o : o[x];
			}, obj);
		},
		getHexColor(color) {
			return this.$vuetify.theme.themes.light[color];
		},
	},
};
