<template>
	<div class="login">
		<v-form class="login-form" ref="form" v-if="!loginData.status">
			<transition name="component-fade" mode="out-in">
				<div key="login" v-if="!forgotten_password">
					<h3>
						Accedi al mondo AILI
					</h3>

					<div class="login-fields">
						<v-text-field class="field" v-model="username" label="E-mail" :color="pageColor"></v-text-field>
						<v-text-field
							v-model="password"
							class="field"
							label="Password"
							type="password"
							:color="pageColor"
						></v-text-field>
					</div>

					<LoginSubmitButton
						@onLoginSuccess="onLoginSuccess"
						@onLoginError="onLoginError"
						:username="username"
						:password="password"
					>
						<Button
							value="Accedi"
							:color="pageColor"
							:disabled="username.length == 0 || password.length == 0"
					/></LoginSubmitButton>
					<p @click="switchForgottenPassword" class="forgot-password font-size-14">
						Password dimenticata?
					</p>
				</div>

				<div key="lost-password" v-else>
					<h3 class="font-size-25">
						Recupera la password
					</h3>
					<div class="login-fields">
						<v-text-field class="field" v-model="username" label="E-mail"></v-text-field>
					</div>
					<Button
						class="forgot-password-button"
						:color="pageColor"
						value="Recupera"
						@click="onForgotPasswordSend"
						:disabled="!forgotPasswordButtonEnabled || username.length == 0"
					/>
					<p @click="switchForgottenPassword" class="forgot-password font-size-14">
						Torna indietro
					</p>
				</div>
			</transition>
		</v-form>

		<div v-if="loginData.status && user_status != 'active'" class="pending-payments">
			<h3 class="is-style-chantal" v-if="user_status == 'pending_subscription_payment'">
				Ci siamo quasi!
			</h3>
			<div class="help-text font-size-18" v-if="user_status == 'pending_subscription_payment'">
				Per poter vedere questo contenuto devi completare la tua iscrizione attraverso il versamento della quota
				associativa.
			</div>
			<h3 class="is-style-chantal" v-if="user_status == 'pending_renewal_payment'">
				Oh no!
			</h3>
			<div class="help-text font-size-18" v-if="user_status == 'pending_renewal_payment'">
				Sembra che la tua tessera socio AILI sia scaduta; per poter vedere questo contenuto è necessario
				rinnovarla.
			</div>
			<div class="logged">
				<!-- <v-btn
					class="login button"
					color="aili-blue"
					@click="goTo('Page', { slug: 'iscriviti' })"
					>{{
						user_status == "pending_renewal_payment"
							? "Rinnova la tua tessera"
							: "Continua la tua registrazione"
					}}</v-btn
				> -->
				<Button
					class="login button"
					color="aili-blue"
					:value="
						user_status == 'pending_renewal_payment'
							? 'Rinnova la tua tessera'
							: 'Continua la tua registrazione'
					"
					@click="goTo('Page', { slug: 'iscriviti' })"
				/>
				<v-tooltip bottom v-model="showTooltip">
					<template v-slot:activator="{ on, attrs }">
						<v-icon
							@click="showTooltip = !showTooltip"
							x-small
							color="aili-blue"
							class="outlined"
							v-bind="attrs"
							v-on="on"
						>
							mdi-help
						</v-icon>
					</template>
					<span>Ogni contributo aiuter&agrave; l'associazione e altre persone come te</span>
				</v-tooltip>
			</div>
		</div>
		<v-slide-y-transition name="fadefast">
			<div v-if="loginData.error && loginData.error !== 'Invalid nonce'" class="login-error">
				Errore: {{ loginData.error }}
			</div>
		</v-slide-y-transition>
	</div>
</template>

<script>
import LoginSubmitButton from '@/components/ui/authentication/LoginSubmitButton.vue';
export default {
	name: 'LoginDialog',
	components: {
		LoginSubmitButton,
	},
	data() {
		return {
			forgotPasswordButtonEnabled: true,
			forgotten_password: false,
			donation_success: false,
			loading_donation: false,
			password: '',
			showSubscribeForm: false,
			username: '',
		};
	},
	unmounted() {
		this.forgotten_password = false;
	},
	methods: {
		onLoginSuccess() {
			this.$emit('onLoginSuccess');
		},
		onLoginError() {
			this.$emit('onLoginError');
		},
		switchForgottenPassword() {
			let loginData = this.loginData;
			loginData.error = '';
			this.updateProperty({
				property: 'loginData',
				value: loginData,
			});
			this.forgotten_password = !this.forgotten_password;
		},
		async onForgotPasswordSend() {
			this.forgotPasswordButtonEnabled = false;
			try {
				await this.$api.post('/qzr-data/lostpassword/', {
					user_login: this.username,
				});
				this.$emit('onForgotPasswordSend', true);
			} catch {
				let loginData = this.loginData;
				loginData.error = 'Email non trovata';
				this.updateProperty({
					property: 'loginData',
					value: loginData,
				});
			}
		},
		paymentStarted() {
			this.loading_donation = true;
		},
		paymentCompleted(payment_intent_id) {
			let donation_status = setInterval(async () => {
				let response = await this.$api.post('/qzr-data/get_payment_status', {
					payment_intent_id: payment_intent_id,
				});
				if (response.data === 'success') {
					this.loading_donation = false;
					this.donation_success = true;
					clearInterval(donation_status);
				}
			}, 1000);
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep #card {
	padding: 10px 20px 11px;
	background-color: $aili-yellow-light;
	border-radius: 20px;
	width: 100%;
	input {
		color: white;
	}
}
::v-deep .card-row {
	margin: $spacing-0 0;
}
::v-deep .donation-button {
	color: $aili-white;
	letter-spacing: normal;
	border-radius: 90px;
	transition: all 0.3s;
	font-size: $font-size-14;
}
h3 {
	text-align: left;
}
.dialog {
	background: $aili-blue;
}

.login {
	height: 100%;
	background: #fff;
	padding: $spacing-1;
	@media (max-width: $tablet-l) {
		width: 100%;
		padding: 0;
	}

	.pending-payments {
		margin: -$spacing-1;
		@media (max-width: $tablet-l) {
			margin: 0;
		}
	}
}
.forgot-password {
	color: #999;
	display: inline-block;
	width: 100%;
	margin: 10px 0;
	cursor: pointer;
}

.login-error {
	border-top: 1px solid #999;
	padding-top: 10px;
	color: red;
	font-size: $font-size-14;
	text-transform: uppercase;
}
.login-form {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	text-align: center;
	overflow: hidden;
}

.login-fields {
	height: 140px;
	display: inline-flex;
	width: 100%;
	align-items: center;
	flex-wrap: wrap;
	@media (max-width: $tablet-l) {
		display: inline-block;
	}
}
.component-fade-enter-active,
.component-fade-leave-active {
	transition: opacity 0.3s ease;
}

.component-fade-enter,
.component-fade-leave-to {
	opacity: 0;
}
.logged {
	display: flex;
	align-items: center;
	justify-content: center;
}
.form-content .login-form .help-text {
	text-align: left;
	padding-bottom: $spacing-0;
}
</style>
