<template>
	<div class="desktop-menu">
		<div class="dropped" :class="{ active: isScrolled }" />
		<MainColumn>
			<Logo class="main-logo" size="100" :color="isScrolled ? pageColor : '#fff'" />
			<div class="menu-wrap">
				<Button
					:color="pageColor"
					:active="currentSlug == page.slug"
					v-for="page in menus"
					:key="page.ID"
					:href="getUrl(page.relative_url)"
					:value="page.title"
					@click="(e) => goToPage(page.slug, e)"
					:noBorder="true"
					:inverted="!isScrolled"
				/>
				<LoginDialogButton v-if="!loginData.user">
					<Button :inverted="!isScrolled" :color="pageColor" value="Accedi" />
				</LoginDialogButton>
				<div
					class="account button"
					:class="{ ['color-' + pageColor]: isScrolled }"
					v-if="loginData.user"
					@click="(e) => goToPage('area-riservata', e)"
				>
					<Button :color="isScrolled ? pageColor : '#fff'" icon="mdi-account" />
				</div>
				<GlobalSearch :isScrolled="isScrolled ? true : false" />
			</div>
		</MainColumn>
	</div>
</template>

<script>
import Logo from '@/components/ui/Logo.vue';
import LoginDialogButton from '@/components/ui/authentication/LoginDialogButton.vue';
import GlobalSearch from '@/components/ui/GlobalSearch.vue';
export default {
	name: 'Header',
	props: ['data', 'isScrolled', 'currentSlug'],
	components: { Logo, LoginDialogButton, GlobalSearch },
	data() {
		return {
			menus: [],
			submenu: [],
		};
	},
	mounted() {
		this.data.map((singleMenu) => {
			this.addToSubmenuIfChild(singleMenu);
		});
	},
	methods: {
		addToSubmenuIfChild(singleMenu) {
			if (singleMenu.menu_item_parent && singleMenu.menu_item_parent > 0) {
				let indexParent = parseInt(singleMenu.menu_item_parent);
				if (!this.submenu[indexParent]) {
					this.submenu[indexParent] = [];
				}

				this.submenu[indexParent].push(singleMenu);
			} else {
				this.menus.push(singleMenu);
			}
		},
		goToPage(slug, e) {
			e.preventDefault();
			switch (slug) {
				case 'articoli':
					this.goTo('Articles');
					break;
				case 'ricette':
					this.goTo('Recipes');
					break;

				default:
					this.goTo('Page', { slug: slug });
			}
		},
	},
};
</script>

<style lang="scss" scoped>
#app {
	.menu-wrap {
		display: inline-flex;
		width: calc(100% - 100px);
		padding: 15px 0;
		padding-right: 22px;
		z-index: 100;
		justify-content: flex-end;
	}

	a {
		text-decoration: none;
		&.active {
			background: #fff;
			pointer-events: none;
			cursor: default;
		}
	}

	.dropped {
		box-shadow: 10px -20px 25px 4px #000;
		position: fixed;
		background: #fff;
		width: 100%;
		top: -70px;
		left: 0;
		right: 0;
		height: 70px;
		transition: 0.3s all;
		&.active {
			top: 0;
		}
	}
	.main-logo {
		float: left;
		margin: 15px 0;
	}
}
</style>
