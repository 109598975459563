<template>
	<v-app>
		<v-main :class="pageColor">
			<!-- <transition name="slide-fade" appear> -->
			<router-view v-if="validationLoaded" :class="'app background-' + pageColor" />
			<!-- </transition> -->
		</v-main>
		<Footer />
	</v-app>
</template>

<script>
import Footer from '@/components/ui/Footer.vue';
import { login } from './components/ui/authentication/Login';

export default {
	mixins: [login],
	components: {
		Footer,
	},
	data() {
		return {
			validationLoaded: false,
		};
	},
	async mounted() {
		let isPasswordRecovered = await this.loginRecover();

		if (!isPasswordRecovered) {
			await this.tryLoginCookies();
		}

		this.validationLoaded = true;
	},
};
</script>

<style lang="scss" scoped>
.app {
	transition: background-color 0.2s linear;
}
</style>
