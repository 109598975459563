<template>
	<div>
		<Header />
		<v-fade-transition>
			<div v-if="!loadingData" class="home">
				<div class="content">
					<v-col no-gutters cols="12" class="background">
						<RenderBlocks :blocks="blocks" />
					</v-col>
				</div>
			</div>
		</v-fade-transition>
	</div>
</template>

<script>
import Header from '@/components/ui/Header.vue';

export default {
	name: 'Home',
	components: {
		Header,
		RenderBlocks: () => import('@/components/blocks/RenderBlocks.vue'),
	},
	metaInfo() {
		return this.formatYoastMeta(this.seo);
	},
	data() {
		return {
			posts: null,
			data: null,
			blocks: null,
		};
	},

	async mounted() {
		this.updateProperty({
			property: 'loadingData',
			value: true,
		});
		this.updateProperty({
			property: 'pageColor',
			value: 'aili-blue',
		});
		if (this.$route.params.login) {
			setTimeout(() => {
				let loginData = this.loginData;
				loginData.dialog = true;
				this.updateProperty({
					property: 'loginData',
					value: loginData,
				});
			}, 1000);
		}
		let response = await this.$api.get('/wp/v2/pages?slug=home');
		let page = response.data[0];
		this.blocks = page.blocks;
		this.updateProperty({
			property: 'seo',
			value: page,
		});
		this.updateProperty({
			property: 'loadingData',
			value: false,
		});
	},
};
</script>

<style lang="scss" scoped>
.home {
	background: $aili-blue;
	min-height: 100vh;
}

#app .content {
	padding-top: 70px;
}
</style>
