<template>
	<v-scroll-y-transition>
		<div class="menu" v-if="pageTitles !== null">
			<v-fade-transition>
				<DesktopMenu :isScrolled="isScrolled" :data="pageTitles" :currentSlug="currentSlug" v-if="isDesktop" />
				<MobileMenu :isScrolled="isScrolled" :data="pageTitles" :currentSlug="currentSlug" v-else />
			</v-fade-transition>
			<LoginDialog />
		</div>
	</v-scroll-y-transition>
</template>

<script>
import DesktopMenu from '@/components/ui/DesktopMenu.vue';
import MobileMenu from '@/components/ui/MobileMenu.vue';
import LoginDialog from '@/components/ui/LoginDialog.vue';
import { mapState } from 'vuex';

export default {
	name: 'Header',
	props: [],
	components: { DesktopMenu, MobileMenu, LoginDialog },
	data() {
		return {
			isScrolled: false,
		};
	},
	computed: {
		...mapState({
			pageTitles: 'pageTitles',
		}),
		isDesktop() {
			return this.$vuetify.breakpoint.width > 1042;
		},
		logoColor() {
			if (document.body.clientWidth < 1024) {
				if (!this.responsiveMenu) {
					return this.pageColor;
				} else {
					return '#fff';
				}
			} else {
				if (this.responsiveMenu) {
					return this.pageColor;
				} else {
					return '#fff';
				}
			}
		},
		currentSlug() {
			switch (this.$route.name) {
				case 'Articles':
					return 'articoli';
				case 'Recipes':
					return 'ricette';
				default:
					return this.$route.params.slug;
			}
		},
	},
	async mounted() {
		let response_profile = await this.$api.get('/qzr-data/qzr_fp_pages/qzr_um_profile_page/');
		this.profile = response_profile.data[0];

		let response = await this.$api.get('/qzr-data/menu');
		let menu = response.data;

		// menu.map((singleMenu) => {
		// 	if (!singleMenu.metas) {
		// 		singleMenu.metas = {
		// 			color_page: this.getColorPage(singleMenu),
		// 		};
		// 	} else {
		// 		singleMenu.metas.color_page = this.getColorPage(singleMenu);
		// 	}
		// });

		this.updateProperty({
			property: 'pageTitles',
			value: menu,
		});

		let offset = 20;
		window.addEventListener('scroll', () => {
			this.isScrolled = window.scrollY > offset;
		});
	},
	methods: {
		// getColorPage(singleMenu) {
		// 	let colorPage;
		// 	if (!singleMenu.metas || !singleMenu.metas.color_page) {
		// 		switch (singleMenu.slug) {
		// 			case 'ricette':
		// 				singleMenu.view = 'Recipes';
		// 				singleMenu.slug = 'ricette';
		// 				colorPage = 'aili-pink';
		// 				break;
		// 			case 'articoli':
		// 				singleMenu.view = 'Articles';
		// 				singleMenu.slug = 'articoli';
		// 				colorPage = 'aili-purple';
		// 				break;
		// 		}
		// 	} else {
		// 		colorPage = singleMenu.metas.color_page[0];
		// 	}
		// 	return colorPage;
		// },
	},
};
</script>

<style lang="scss" scoped>
.menu {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
}
</style>
