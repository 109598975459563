<template>
	<v-slide-y-reverse-transition>
		<div
			v-if="!cookies && privacyPolicySlug"
			id="cookies-policy"
			class="col-padding row-padding"
		>
			<MainColumn>
				Questo sito raccoglie dati statistici anonimi sulla navigazione.
				Sono presenti cookie installati da terze parti. Continuando a
				navigare su questo sito, accetti il servizio e gli stessi
				cookie.
				<br />
				<a
					href="https://associazioneaili.it/wp-content/uploads/policies/privacy.pdf"
					title="Privacy Policy Aili Associazione Italiana Latto-Intolleranti"
					target="_blank"
					rel="noreferrer"
					>Privacy policy</a
				>
				<a
					href="https://associazioneaili.it/wp-content/uploads/policies/cookie.pdf"
					title="Cookie Policy Aili Associazione Italiana Latto-Intolleranti"
					target="_blank"
					class="cookies-policy-btn"
					rel="noreferrer"
					>Cookie policy</a
				>
				<span
					@click="createCookie('acceptCookies', 'acceptCookies')"
					class="cookies-policy-btn"
					>OK</span
				>
				
			</MainColumn>
		</div>
	</v-slide-y-reverse-transition>
</template>

<script>
export default {
	data() {
		return {
			cookies: false,
			privacyPolicySlug: null
		};
	},
	async mounted() {
		this.cookies = this.$cookies.get("acceptCookies");
		let response = await this.$api.get(
			"/qzr-data/qzr_fp_pages/qzr_privacy_policy"
		);
		this.privacyPolicySlug = response.data[0].post_name;
	},
	methods: {
		createCookie(name, value) {
			let today = new Date();
			let expire = new Date();
			expire.setTime(today.getTime() + 3600000 * 24 * 14);
			this.$cookies.set(name, value, expire.toGMTString());
			this.cookies = true;
		}
	}
};
</script>

<style lang="scss" scoped>
#cookies-policy a {
	color: #fff;
}

#cookies-policy a:hover {
	color: #00aeef;
}

#cookies-policy {
	left: 0;
	cursor: pointer;
	padding: 30px 0;
	font-size: $font-size-12;
	position: fixed;
	bottom: 0;
	z-index: 10000;
	background: rgba(0, 0, 0, 0.8);
	width: 100%;
	color: #fff;
}
.cookies-policy-btn {
	font-weight: bold;
	color: #fff;
	text-decoration: underline;
	padding: 0px 10px;
	text-align: center;
	cursor: pointer;
}
.cookies-policy-btn:hover {
	color: #00aeef;
}
</style>
