import { utilities } from './mixins/utilities';
import vuetify from './plugins/vuetify';
import store from './store';
import router from './router';
import i18n from './i18n';
import axios from 'axios';
import VueSocialSharing from 'vue-social-sharing';
import VueMeta from 'vue-meta';
import VueCookies from 'vue-cookies';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import Vue from 'vue';
import App from './App.vue';
import 'swiper/css/swiper.css';
// import '@mdi/font/css/materialdesignicons.css';
import Embed from 'v-video-embed';
import './plugins/facebook-pixel.js';

Vue.use(Embed);
Vue.use(VueSocialSharing);
Vue.use(VueMeta);
Vue.use(VueCookies);
Vue.use(VueAwesomeSwiper);
Vue.mixin(utilities);

Vue.use({
	install(Vue) {
		const ax = axios.create({
			baseURL: 'https://www.associazioneaili.it/wp-json',
			/*	process.env.NODE_ENV == 'production'
					? process.env.VUE_APP_ENDPOINT
					: 'http://qzrstudio.com' + process.env.VUE_APP_ENDPOINT,
			   'http://localhost' + process.env.VUE_APP_ENDPOINT, */

			withCredentials: true
		});
		ax.interceptors.request.use((config) => {
			config.params = config.params || {};
			config.params['vue'] = true;
			return config;
		});
		Vue.prototype.$api = ax;

		const localAxios = axios.create({
			withCredentials: true
		});
		Vue.prototype.$localApi = localAxios;
	}
});

Vue.config.productionTip = false;

var infiniteScroll = require('vue-infinite-scroll');
Vue.use(infiniteScroll);

new Vue({
	store,
	router,
	vuetify,
	i18n,
	render: (h) => h(App)
}).$mount('#app');
