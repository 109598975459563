<template>
	<v-fade-transition>
		<footer v-if="!loadingData" class="font-weight-medium footer">
			<MainColumn>
				<v-row>
					<v-col md="3" cols="6">
						<img src="../../img/logo.svg" />
					</v-col>
					<v-col md="3" cols="12">
						<v-col cols="12" class="value">
							AILI - Associazione Italiana Latto-Intolleranti APS<br />
							Sede legale:<br />
							Via Dorati, 86 - 55100 Lucca<br />
							C.F. 92055990466
						</v-col>
						<br />
						<span class="label">Note legali</span>
						<v-col cols="12">
							<a
								href="https://associazioneaili.it/wp-content/uploads/policies/privacy.pdf"
								title="Privacy Policy Aili Associazione Italiana Latto-Intolleranti"
								class="value"
								target="_blank"
								rel="noreferrer"
								>Privacy policy</a
							><br />
							<a
								href="https://associazioneaili.it/wp-content/uploads/policies/cookie.pdf"
								title="Cookie Policy Aili Associazione Italiana Latto-Intolleranti"
								class="value"
								target="_blank"
								rel="noreferrer"
								>Cookie policy</a
							>
						</v-col>
					</v-col>

					<v-col md="3" cols="12">
						<span class="label">Contatti</span>
						<v-col cols="12">
							<a
								href="mailto:info@associazioneaili.it"
								target="_blank"
								title="Email Aili Associazione Italiana Latto-Intolleranti"
								class="value"
								rel="noreferrer"
								>info@associazioneaili.it</a
							>
						</v-col>
						<v-col cols="12">
							<a
								href="tel:+393292854291"
								target="_blank"
								title="Telefono Aili Associazione Italiana Latto-Intolleranti"
								class="value"
								rel="noreferrer"
								>+393292854291</a
							>
						</v-col>
						<v-col cols="12">
							<br />
						</v-col>
						<v-row no-gutters>
							<v-col>
								<span class="label">Seguici su</span>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="12">
								<a
									href="https://www.facebook.com/AssociazioneAILI"
									target="_blank"
									title="Facebook Aili Associazione Italiana Latto-Intolleranti"
									class="social-link"
									rel="noreferrer"
								>
									<v-icon dark left large>
										mdi-facebook
									</v-icon>
								</a>
								<a
									href="https://instagram.com/lfree_aili"
									target="_blank"
									title="Instagram Aili Associazione Italiana Latto-Intolleranti"
									class="social-link"
									rel="noreferrer"
								>
									<v-icon dark left large>
										mdi-instagram
									</v-icon>
								</a>
								<a
									href="https://www.youtube.com/channel/UCfRZajpkqrxFgCB1nOX9SDA"
									target="_blank"
									title="Youtube Aili Associazione Italiana Latto-Intolleranti"
									class="social-link"
									rel="noreferrer"
								>
									<v-icon dark left large>
										mdi-youtube
									</v-icon>
								</a>
								<br /><br />
								<span class="label">DESIGN BY</span>
								<v-col cols="12">
									<a
										href="https://www.qzrstudio.com"
										target="_blank"
										title="QZR studio, Lucca"
										class="value"
										rel="noreferrer"
										>QZR Studio</a
									>
								</v-col>
							</v-col>
						</v-row>
					</v-col>
					<v-col md="3" cols="12">
						<v-col cols="12" class="value">
							&copy; {{ year }} - AILI APS
							<div class="disclaimer">
								Gli argomenti affrontati in questo sito sono esclusivamente a scopo informativo.<br />
								Non rappresentano una diagnosi né vogliono sostituire il rapporto medico-paziente o una
								visita specialistica. Per un'accurata diagnosi consigliamo di rivolgersi al medico di
								fiducia e/o a uno specialista.
							</div>
						</v-col>
					</v-col>
				</v-row>
			</MainColumn>
			<Cookies />
		</footer>
	</v-fade-transition>
</template>

<script>
import Cookies from '@/components/ui/Cookies.vue';

export default {
	name: 'Footer',
	components: {
		Cookies,
	},
	props: ['value', 'color'],
	data() {
		return {
			year: false,
		};
	},
	mounted() {
		this.year = new Date().getFullYear();
	},
};
</script>

<style lang="scss" scoped>
address {
	font-style: unset;
}
.label {
	margin-bottom: $spacing-1;
	font-size: $font-size-14;
	text-transform: uppercase;
}
.value {
	font-size: $font-size-14;
	color: white;
}
.social-link {
	text-decoration: none;
}

.footer {
	z-index: 100;
	background-color: $aili-black;
	color: $aili-white;
	padding: $spacing-1;

	@media (max-width: $mobile-m) {
		padding: $spacing-1 $spacing-0/2;
	}
}
img {
	width: 100px;
}
.disclaimer {
	padding-top: $spacing-0;
}
</style>
