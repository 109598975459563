<template>
	<div class="wrapper">
		<input v-model="search" class="search color-aili-white" color="white" />
		<v-icon class="magnify" @click="onGlobalSearchClick" color="white">
			mdi-magnify
		</v-icon>
	</div>
</template>
<script>
export default {
	props: ['isScrolled'],
	data() {
		return {
			is_writing: false,
			search: '',
			status: false,
		};
	},

	methods: {
		onGlobalSearchClick() {
			this.$emit('onGlobalSearch');
			let old_search = this.$route.params.term;
			if (old_search !== this.search) {
				this.updateProperty({
					property: 'globalSearchTerm',
					value: this.search,
				});
				this.goTo('Search', {
					term: this.search,
				});
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.wrapper {
  right: 0;
  display: inline-flex;
  width: 100%;
  margin: 15px 0;
  color: #fff;
  .search {
    width: calc(100% - 40px);
    color: #fff;
    border-bottom: 2px solid #fff;
    &:focus {
      outline: 0;
    }
  }
  .magnify {
    position: absolute;
    right: 0;
  }
}
</style>
